/**
 * a FilterTerms object wrapping an array of FilterTerms - lol
 */
export default class FilterTerms {
  /**
     * @param {FilterTermType} type
     * @param {String} label
     * @param {number} position
     * @param {String} filterGroupType @TODO type
     * @param {FilterTermSortBy} sortBy
     * @param {FilterTerm[]} terms
     */
  constructor (type, label, position, filterGroupType, sortBy, terms) {
    /**
         * @type {String}
         */
    this.type = type
    /**
         * Name to display in the view
         * @type {String}
         */
    this.label = label
    /**
         * Array of FieldTerms
         * @type {FilterTerm[]}
         */
    this.terms = terms

    /**
         * @type {number}
         */
    this.position = position

    /**
         * @type {string}
         */
    this.filterGroupType = filterGroupType

    /**
         * @type {FilterTermSortBy}
         */
    this.sortBy = sortBy
  }
}
