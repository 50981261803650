/**
 * Request for Field-Term auto completion
 */
export default class FieldTermAutocompleteRequest {
  /**
     * constructs FilterSearchRequest
     * @param {string} fieldSearchTermType
     * @param {string} fieldSearchTerm
     * @param {String} searchTerm
     * @param {boolean} isAvailable
     * @param {FieldTerms[]} fieldTerms
     * @param {FilterTerms[]} filterTerms
     * @param {String|null} releaseDateFrom
     * @param {String|null} releaseDateTo
     */
  constructor (fieldSearchTermType, fieldSearchTerm, searchTerm, isAvailable, fieldTerms, filterTerms, releaseDateFrom, releaseDateTo) {
    /**
         * The type of the fieldTerm
         * @type {string}
         */
    this.fieldSearchTermType = fieldSearchTermType

    /**
         * @type {string}
         */
    this.fieldSearchTerm = fieldSearchTerm

    /**
         * The term to search for
         * @type {String}
         */
    this.searchTerm = searchTerm

    /**
         * @type {boolean}
         */
    this.isAvailable = isAvailable
    /**
         *
         * @type {FieldTerms[]}
         */
    this.fieldTerms = fieldTerms
    /**
         *
         * @type {FilterTerms[]}
         */
    this.filterTerms = filterTerms

    /**
         * @type {String}
         */
    this.releaseDateFrom = releaseDateFrom
    /**
         *
         * @type {String}
         */
    this.releaseDateTo = releaseDateTo
  }
}
