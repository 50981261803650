// i18n-setup.js
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from './lang/de'
import moment from 'moment'
import 'moment/locale/de'

export const defaultLanguage = 'de'
Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: defaultLanguage, // set locale
  fallbackLocale: defaultLanguage,
  messages, // set locale messages
  silentTranslationWarn: process.env.NODE_ENV === 'production'
})

export const possibleLanguages = [defaultLanguage, 'en']
const loadedLanguages = [defaultLanguage] // our default language that is preloaded

function setI18nLanguage (lang) {
  i18n.locale = lang
  moment.locale(lang)
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

export function loadLanguageAsync (lang) {
  if (i18n.locale !== lang) {
    if (!loadedLanguages.includes(lang)) {
      return import(/* webpackChunkName: "lang-[request]" */ `./lang/${lang}`)
        .then(msgs => {
          i18n.setLocaleMessage(lang, msgs.default[lang])
          loadedLanguages.push(lang)
          return setI18nLanguage(lang)
        })
    }
    return Promise.resolve(setI18nLanguage(lang))
  }
  return Promise.resolve(lang)
}
