/**
 * Request for Field-Term auto completion
 */
export default class AutocompleteRequest {
  /**
     * @param {string} searchTerm
     */
  constructor (searchTerm, isAvailable) {
    /**
         * @type {string}
         */
    this.searchTerm = searchTerm

    /**
         * @type {Boolean}
         */
    this.isAvailable = isAvailable
  }
}
