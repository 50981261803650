export default {
  de: {
    category: {
      voice_and_image: 'Ton- und Bildträger',
      pop_rock: 'Pop / Rock',
      classic: 'Klassik',
      cds: 'CDs',
      vinyl: 'Vinyl',
      mcs: 'MCs',
      videos_movies: 'Videos / Filme',
      dvds: 'DVDs',
      blueray: 'Blu-ray',
      ebooks_audiobooks: 'Bücher / Hörbucher',
      kids_and_family: 'Kinder / Family',
      merchandise: 'Merchandise',
      clothing_accessories: 'Kleidung',
      interactive: 'Interactive',
      games_software: 'Games / Software',
      other: 'Sonstiges'
    },
    landing_page: {
      greeting_header: 'Herzlich willkommen auf musicline.de!',
      greeting_message: 'Sie suchen nach Neuerscheinungen und möchten in die Titel reinhören? Mit der Suchmaschine musicline.de finden Sie alle derzeit in Deutschland erhältlichen Artikel, egal ob CD, Video, Blu-ray, Hörbücher oder Merchandise-Artikel. ' +
                'Stöbern Sie nach Formaten, lassen Sie sich alle Produkte zu Ihrer Lieblingsband anzeigen oder finden Sie mit Hilfe der detaillierten Suche Neuerscheinungen verschiedener Genres. ' +
                'Seien Sie neugierig auf einen vielfältigen Musik- und Entertainmentmarkt!'
    },
    links: {
      home: 'Home',
      look_around: {
        title: 'Stöbern',
        vinyl: 'Vinyl',
        cd: 'CD',
        metal: 'Metal'
      }
    },
    product: {
      release_date: 'VÖ',
      gtin: 'GTIN',
      label: 'Label',
      distributor: 'Vertrieb',
      genre: 'Genre',
      configuration: 'Produkttyp',
      set_total: 'Set-Inhalt',
      sample_exists: 'Hörprobe vorhanden',
      multiple_artists: 'Künstler',
      tracks: {
        title: 'Titel',
        duration: 'Dauer'
      }
    },
    artist: {
      alternative_names: 'Weitere Künstlernamen',
      records_with: 'Aufnahmen mit',
      discography: 'Diskographie'
    },
    search: {
      placeholder: 'Suche...',
      filters: 'Filter',
      reset_filters: 'Filter zurücksetzen',
      searching_for: 'Suche nach "{0}"',
      searching_for_all: 'Suche nach allem',
      found_for_query: '{0} Suchergebnisse für "{1}"',
      found_for_query_all: '{0} Suchergebnisse'
    },
    filtersearch: {
      release_date_range: {
        title: 'VÖ',
        startDate: 'von',
        endDate: 'bis'
      },
      filter: {
        maximum_filters_displayed: 'Wir können leider nicht alle {0} Ergebnisse hier anzeigen, nutze das Eingabefeld um die Ergebnisse zu reduzieren.',
        term: {
          type: {
            labelRaw: 'Label',
            genre: 'Genre',
            media: 'Produkttyp',
            set_total_group: 'Set-Inhalt'
          }
        }
      },
      field: {
        no_suggestions: 'Keine Vorschläge vorhanden.',
        term: {
          type: {
            artist: 'Künstler',
            title: 'Titel',
            't-title': 'Tracktitel'
          }
        }
      }
    },
    order_by: {
      score: 'Relevanz',
      artist_sort: 'Künstler',
      title_sort: 'Titel',
      released: 'VÖ'
    },
    error: {
      header: '404',
      body: 'Die von Ihnen gesuchte Seite konnte leider nicht gefunden werden'
    },
    imprint: {
      title: 'Impressum'
    },
    data_privacy: {
      title: 'Datenschutz'
    },
    charts: {
      title: 'Charts',
      hint: 'Suchst du Informationen zu Charts? Schau dir www.offiziellecharts.de an'
    },
    loading: 'Lädt...'
  }
}
