import config from '../config'

/**
 * Service for search
 */
class FilterSearchService {
  /**
     * @typedef {Object} SearchResultResponse
     * @property {SearchResult} searchResult
     */

  /**
     * @typedef {Object} SearchResult
     * @property {number} offset
     * @property {number} limit
     * @property {number} total
     * @property {SearchResultItem[]} resultItems
     *
     * @property {SearchFilterGroupItem[]} filterGroupItems
     * @property {SearchFieldGroupItem[]} fieldGroupItems
     */

  /**
     * @typedef {Object} SearchResultItem
     * @property {string} title
     * @property {string} artist
     * @property {string} physical_release_date
     * @property {string} genre
     * @property {string} configuration
     * @property {string} label_name
     * @property {string} gtin
     * @property {string} distributor_name
     * @property {string} artwork
     * @property {number} set_total
     */

  /**
     * @typedef {Object} SearchResultItem
     * @property {string} title
     * @property {string} artist
     * @property {string} physical_release_date
     * @property {string} genre
     * @property {string} configuration
     * @property {string} label_name
     * @property {string} gtin
     * @property {string} distributor_name
     * @property {string} artwork
     * @property {number} set_total
     */

  /**
     * @typedef {Object} SearchFilterGroupItem
     * @property {string} label
     * @property {number} position
     * @property {string} type
     * @property {string} filterGroupType
     * @property {string} sortBy
     * @property {SearchFilterResultItem[]} filterResultItems
     */

  /**
     * @typedef {Object} SearchFilterResultItem
     * @property {string} label
     * @property {string} term
     * @property {number} count
     * @property {boolean|null} active
     * @property {boolean} disabled
     */

  /**
     * @typedef {Object} SearchFieldGroupItem
     * @property {string} label
     * @property {number} position
     * @property {string} type
     * @property {string} fieldGroupType
     * @property {SearchFieldResultItem} fieldResultItems
     */

  /**
     * @typedef {Object} SearchFieldResultItem
     * @property {string} label
     * @property {string} term
     */

  /**
     * @param {FilterSearchRequest} filterSearchQuery
     * @return {Promise}
     */
  send (filterSearchQuery) {
    const url = config['api-baseurl'] + '/api/search'
    return new Promise((resolve, reject) => {
      $.ajax({
        url: url,
        dataType: 'json',
        method: 'POST',
        contentType: 'application/json',
        data: JSON.stringify(filterSearchQuery),
        processData: false
      })
        .done((data) => {
          resolve(data.searchResult)
        })
        .fail((jqXHR, textStatus, errorThrown) => {
          reject(jqXHR, textStatus, errorThrown)
        })
    })
  }
}

export const instance = new FilterSearchService()
