/**
 * Request for search
 */
export default class FilterSearchRequest {
  /**
     * constructs FilterSearchRequest
     * @param {String} searchTerm
     * @param {boolean} isAvailable
     * @param {OrderBy} orderBy
     * @param {boolean} isReverseOrder
     * @param {number} offset
     * @param {number} limit
     * @param {boolean} analyzeFilters
     * @param {FieldTerms[]} fieldTerms
     * @param {FilterTerms[]} filterTerms
     * @param {String|null} releaseDateFrom
     * @param {String|null} releaseDateTo
     */
  constructor (searchTerm, isAvailable, orderBy, isReverseOrder, offset, limit, analyzeFilters, fieldTerms, filterTerms, releaseDateFrom, releaseDateTo) {
    /**
         * The term to search for
         * @type {String}
         */
    this.searchTerm = searchTerm

    /**
         * @type {boolean}
         */
    this.isAvailable = isAvailable

    /**
         * @type {string}
         */
    this.orderBy = orderBy
    /**
         * @type {boolean}
         */
    this.isReverseOrder = isReverseOrder
    /**
         * @type {number}
         */
    this.offset = offset
    /**
         * @type {number}
         */
    this.limit = limit
    /**
         * @type {boolean}
         */
    this.analyzeFilters = analyzeFilters

    this.fieldTerms = fieldTerms
    this.filterTerms = filterTerms

    /**
         *
         * @type {String}
         */
    this.releaseDateFrom = releaseDateFrom
    /**
         *
         * @type {String}
         */
    this.releaseDateTo = releaseDateTo

    /**
         *
         * @type {string}
         */
    this.releaseDateTerm = 'custom'
  }
}
