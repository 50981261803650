<template>
    <b-navbar toggleable="lg" class="mb-0" type="dark" variant="dark">
        <div class="container">
            <b-navbar-brand class="navbar-translate p-lg-1 mr-lg-1 mr-0">
                <router-link to="/">
                    <img :src="logo" width="60">
                </router-link>
                <button type="button" aria-label="Toggle navigation" @click="toggleNavbar"
                        :aria-expanded="navbarOpen"
                        aria-controls="navbarNavDropdown" class="navbar-toggler-custom">
                    <span class="navbar-toggler-icon"></span>
                    <span class="navbar-toggler-icon"></span>
                    <span class="navbar-toggler-icon"></span>
                </button>
            </b-navbar-brand>
            <!-- don't use bootstrap-vue for collapse and toggler here. Breaks material kit -->
            <div ref="navBarCollapse" class="collapse navbar-collapse" id="navbarNavDropdown">
                <b-navbar-nav @click="hideNavbar">
                    <b-nav-item class="pl-lg-4" to="/">
                        {{ $t('links.home') }}
                    </b-nav-item>
                    <b-nav-item-dropdown :text="$t('links.look_around.title')" right>
                        <div is="category-component"
                             v-for="category in categories"
                             :key="category.label"
                             :label="category.label"
                             :searchFilterGroupItem="category.searchFilterGroupItem">
                        </div>
                    </b-nav-item-dropdown>
                </b-navbar-nav>
                <hr class="d-md-none">
                <b-navbar-nav class="ml-auto">
                    <auto-completer-component @hideNavbar="hideNavbar"></auto-completer-component>
                </b-navbar-nav>
            </div>
        </div>
    </b-navbar>
</template>

<script>
import AutoCompleterComponent from './AutoCompleterComponent.vue'
import CategoryComponent from './CategoryComponent.vue'
import ArticleCategory from '../domain/ArticleCategory'
import FilterTerm from '../domain/FilterTerm'
import logo from '../assets/img/logo.png'

export default {
  name: 'NavBarComponent',
  components: {
    'auto-completer-component': AutoCompleterComponent,
    'category-component': CategoryComponent
  },
  data: function () {
    return {
      categories: [],
      logo,
      navbarOpen: false
    }
  },
  computed: {
    breakpointLarge () {
      return $(window).width() > 992
    }
  },
  methods: {
    createFilterTerm (term) {
      return new FilterTerm(term, term, 0, false, true)
    },
    toggleNavbar () {
      if (this.navbarOpen) {
        this.hideNavbar()
      } else {
        this.showNavbar()
      }
    },
    hideNavbar () {
      if (this.breakpointLarge) return
      $(this.$refs.navBarCollapse).collapse('hide')
      $('html').removeClass('nav-open')
      this.navbarOpen = false
    },
    showNavbar () {
      if (this.breakpointLarge) return
      $(this.$refs.navBarCollapse).collapse('show')
      $('html').addClass('nav-open')
      this.navbarOpen = true
    }
  },
  created: function () {
    this.categories.push(new ArticleCategory('category.voice_and_image', {
      label: 'filtersearch.filter.term.type.media',
      position: 7,
      type: 'MEDIA',
      filterGroupType: 'CHECKBOX_EXPANDABLE',
      sortBy: 'COUNT_DESC',
      filterResultItems: [
        this.createFilterTerm('0001'),
        this.createFilterTerm('0002'),
        this.createFilterTerm('0003'),
        this.createFilterTerm('0004'),
        this.createFilterTerm('0005'),
        this.createFilterTerm('0006'),
        this.createFilterTerm('0007'),
        this.createFilterTerm('0008'),
        this.createFilterTerm('0010'),
        this.createFilterTerm('0011'),
        this.createFilterTerm('0012'),
        this.createFilterTerm('0013'),
        this.createFilterTerm('0014'),
        this.createFilterTerm('0015'),
        this.createFilterTerm('0016'),
        this.createFilterTerm('0017'),
        this.createFilterTerm('0019'),
        this.createFilterTerm('0026'),
        this.createFilterTerm('0027'),
        this.createFilterTerm('0028'),
        this.createFilterTerm('0029'),
        this.createFilterTerm('0030'),
        this.createFilterTerm('0031'),
        this.createFilterTerm('0032'),
        this.createFilterTerm('0033'),
        this.createFilterTerm('0034'),
        this.createFilterTerm('0035'),
        this.createFilterTerm('0036'),
        this.createFilterTerm('0038'),
        this.createFilterTerm('0039'),
        this.createFilterTerm('0041'),
        this.createFilterTerm('0042'),
        this.createFilterTerm('0043'),
        this.createFilterTerm('0044'),
        this.createFilterTerm('0045'),
        this.createFilterTerm('0046'),
        this.createFilterTerm('0047'),
        this.createFilterTerm('0048'),
        this.createFilterTerm('0049'),
        this.createFilterTerm('0050'),
        this.createFilterTerm('0051'),
        this.createFilterTerm('0052'),
        this.createFilterTerm('0053'),
        this.createFilterTerm('0054'),
        this.createFilterTerm('0055'),
        this.createFilterTerm('0056'),
        this.createFilterTerm('0057'),
        this.createFilterTerm('0058'),
        this.createFilterTerm('0059'),
        this.createFilterTerm('0060'),
        this.createFilterTerm('0061'),
        this.createFilterTerm('0062'),
        this.createFilterTerm('0063'),
        this.createFilterTerm('0064'),
        this.createFilterTerm('0065'),
        this.createFilterTerm('0066'),
        this.createFilterTerm('0067'),
        this.createFilterTerm('0068'),
        this.createFilterTerm('0069'),
        this.createFilterTerm('0070'),
        this.createFilterTerm('0071'),
        this.createFilterTerm('0072'),
        this.createFilterTerm('0073'),
        this.createFilterTerm('0074'),
        this.createFilterTerm('0075'),
        this.createFilterTerm('0076'),
        this.createFilterTerm('0077'),
        this.createFilterTerm('0078'),
        this.createFilterTerm('0079'),
        this.createFilterTerm('0080'),
        this.createFilterTerm('0081'),
        this.createFilterTerm('0082'),
        this.createFilterTerm('0083'),
        this.createFilterTerm('0084'),
        this.createFilterTerm('0085'),
        this.createFilterTerm('0086'),
        this.createFilterTerm('0087'),
        this.createFilterTerm('0088'),
        this.createFilterTerm('0089'),
        this.createFilterTerm('0090'),
        this.createFilterTerm('0091'),
        this.createFilterTerm('0092'),
        this.createFilterTerm('0097'),
        this.createFilterTerm('0098'),
        this.createFilterTerm('0099')
      ]
    }))

    this.categories.push(new ArticleCategory('category.pop_rock', {
      label: 'filtersearch.filter.term.type.genre',
      position: 5,
      type: 'GENRE',
      filterGroupType: 'CHECKBOX_EXPANDABLE',
      sortBy: 'COUNT_DESC',
      filterResultItems: [
        this.createFilterTerm('101'),
        this.createFilterTerm('102'),
        this.createFilterTerm('103'),
        this.createFilterTerm('104'),
        this.createFilterTerm('105'),
        this.createFilterTerm('106'),
        this.createFilterTerm('107'),
        this.createFilterTerm('108'),
        this.createFilterTerm('109'),
        this.createFilterTerm('110'),
        this.createFilterTerm('111'),
        this.createFilterTerm('112'),
        this.createFilterTerm('113'),
        this.createFilterTerm('114'),
        this.createFilterTerm('115'),
        this.createFilterTerm('116'),
        this.createFilterTerm('117'),
        this.createFilterTerm('118'),
        this.createFilterTerm('119'),
        this.createFilterTerm('123'),
        this.createFilterTerm('124'),
        this.createFilterTerm('127'),
        this.createFilterTerm('128'),
        this.createFilterTerm('180'),
        this.createFilterTerm('181'),
        this.createFilterTerm('182'),
        this.createFilterTerm('187'),
        this.createFilterTerm('188'),
        this.createFilterTerm('189'),
        this.createFilterTerm('190'),
        this.createFilterTerm('191'),
        this.createFilterTerm('192'),
        this.createFilterTerm('193'),
        this.createFilterTerm('195'),
        this.createFilterTerm('196'),
        this.createFilterTerm('197'),
        this.createFilterTerm('198')
      ]
    }))

    this.categories.push(new ArticleCategory('category.classic', {
      label: 'filtersearch.filter.term.type.genre',
      position: 5,
      type: 'GENRE',
      filterGroupType: 'CHECKBOX_EXPANDABLE',
      sortBy: 'COUNT_DESC',
      filterResultItems: [
        this.createFilterTerm('201'),
        this.createFilterTerm('202'),
        this.createFilterTerm('203'),
        this.createFilterTerm('204'),
        this.createFilterTerm('205'),
        this.createFilterTerm('206'),
        this.createFilterTerm('207'),
        this.createFilterTerm('208'),
        this.createFilterTerm('209')
      ]
    }))

    this.categories.push(new ArticleCategory('category.cds', {
      label: 'filtersearch.filter.term.type.media',
      position: 4,
      type: 'MEDIA',
      filterGroupType: 'CHECKBOX_EXPANDABLE',
      sortBy: 'COUNT_DESC',
      filterResultItems: [
        this.createFilterTerm('0006'),
        this.createFilterTerm('0007'),
        this.createFilterTerm('0008'),
        this.createFilterTerm('0015'),
        this.createFilterTerm('0026'),
        this.createFilterTerm('0028'),
        this.createFilterTerm('0029'),
        this.createFilterTerm('0031'),
        this.createFilterTerm('0033'),
        this.createFilterTerm('0034'),
        this.createFilterTerm('0035'),
        this.createFilterTerm('0038'),
        this.createFilterTerm('0039'),
        this.createFilterTerm('0044'),
        this.createFilterTerm('0046'),
        this.createFilterTerm('0047'),
        this.createFilterTerm('0048'),
        this.createFilterTerm('0049'),
        this.createFilterTerm('0051'),
        this.createFilterTerm('0052'),
        this.createFilterTerm('0053'),
        this.createFilterTerm('0054'),
        this.createFilterTerm('0055'),
        this.createFilterTerm('0057'),
        this.createFilterTerm('0058'),
        this.createFilterTerm('0067'),
        this.createFilterTerm('0069'),
        this.createFilterTerm('0074'),
        this.createFilterTerm('0076'),
        this.createFilterTerm('0079'),
        this.createFilterTerm('0080'),
        this.createFilterTerm('0084'),
        this.createFilterTerm('0086')
      ]
    }))

    this.categories.push(new ArticleCategory('category.vinyl', {
      label: 'filtersearch.filter.term.type.genre',
      position: 6,
      type: 'MEDIA',
      filterGroupType: 'CHECKBOX_EXPANDABLE',
      sortBy: 'COUNT_DESC',
      filterResultItems: [
        this.createFilterTerm('0001'),
        this.createFilterTerm('0002'),
        this.createFilterTerm('0003'),
        this.createFilterTerm('0004'),
        this.createFilterTerm('0019'),
        this.createFilterTerm('0047'),
        this.createFilterTerm('0059'),
        this.createFilterTerm('0075'),
        this.createFilterTerm('0084'),
        this.createFilterTerm('0085'),
        this.createFilterTerm('0087'),
        this.createFilterTerm('0088'),
        this.createFilterTerm('0089')
      ]
    }))

    this.categories.push(new ArticleCategory('category.mcs', {
      label: 'filtersearch.filter.term.type.media',
      position: 8,
      type: 'MEDIA',
      filterGroupType: 'CHECKBOX_EXPANDABLE',
      sortBy: 'COUNT_DESC',
      filterResultItems: [
        this.createFilterTerm('0005'),
        this.createFilterTerm('0012'),
        this.createFilterTerm('0036')
      ]
    }))

    this.categories.push(new ArticleCategory('category.videos_movies', {
      label: 'filtersearch.filter.term.type.genre',
      position: 4,
      type: 'GENRE',
      filterGroupType: 'CHECKBOX_EXPANDABLE',
      sortBy: 'COUNT_DESC',
      filterResultItems: [
        this.createFilterTerm('401'),
        this.createFilterTerm('402'),
        this.createFilterTerm('403'),
        this.createFilterTerm('404'),
        this.createFilterTerm('405'),
        this.createFilterTerm('406'),
        this.createFilterTerm('407'),
        this.createFilterTerm('408'),
        this.createFilterTerm('409'),
        this.createFilterTerm('410'),
        this.createFilterTerm('411'),
        this.createFilterTerm('412'),
        this.createFilterTerm('413'),
        this.createFilterTerm('414'),
        this.createFilterTerm('415'),
        this.createFilterTerm('416'),
        this.createFilterTerm('417'),
        this.createFilterTerm('418'),
        this.createFilterTerm('419'),
        this.createFilterTerm('420'),
        this.createFilterTerm('421'),
        this.createFilterTerm('422'),
        this.createFilterTerm('423'),
        this.createFilterTerm('424'),
        this.createFilterTerm('425'),
        this.createFilterTerm('426'),
        this.createFilterTerm('427'),
        this.createFilterTerm('428'),
        this.createFilterTerm('429'),
        this.createFilterTerm('430'),
        this.createFilterTerm('431'),
        this.createFilterTerm('432'),
        this.createFilterTerm('433'),
        this.createFilterTerm('434'),
        this.createFilterTerm('435'),
        this.createFilterTerm('436'),
        this.createFilterTerm('437'),
        this.createFilterTerm('438'),
        this.createFilterTerm('439'),
        this.createFilterTerm('440'),
        this.createFilterTerm('441'),
        this.createFilterTerm('442'),
        this.createFilterTerm('443'),
        this.createFilterTerm('444'),
        this.createFilterTerm('445'),
        this.createFilterTerm('446'),
        this.createFilterTerm('447'),
        this.createFilterTerm('448'),
        this.createFilterTerm('449'),
        this.createFilterTerm('450'),
        this.createFilterTerm('451'),
        this.createFilterTerm('452'),
        this.createFilterTerm('453'),
        this.createFilterTerm('454'),
        this.createFilterTerm('455'),
        this.createFilterTerm('456'),
        this.createFilterTerm('457'),
        this.createFilterTerm('458'),
        this.createFilterTerm('459'),
        this.createFilterTerm('460'),
        this.createFilterTerm('461'),
        this.createFilterTerm('462'),
        this.createFilterTerm('463')
      ]
    }))

    this.categories.push(new ArticleCategory('category.dvds', {
      label: 'filtersearch.filter.term.type.media',
      position: 1,
      type: 'MEDIA',
      filterGroupType: 'CHECKBOX_EXPANDABLE',
      sortBy: 'COUNT_DESC',
      filterResultItems: [
        this.createFilterTerm('0027'),
        this.createFilterTerm('0041'),
        this.createFilterTerm('0042'),
        this.createFilterTerm('0043'),
        this.createFilterTerm('0045'),
        this.createFilterTerm('0046'),
        this.createFilterTerm('0051'),
        this.createFilterTerm('0053'),
        this.createFilterTerm('0054'),
        this.createFilterTerm('0056'),
        this.createFilterTerm('0059'),
        this.createFilterTerm('0063'),
        this.createFilterTerm('0064'),
        this.createFilterTerm('0065'),
        this.createFilterTerm('0068'),
        this.createFilterTerm('0071'),
        this.createFilterTerm('0072'),
        this.createFilterTerm('0073'),
        this.createFilterTerm('0082'),
        this.createFilterTerm('0083')
      ]
    }))

    this.categories.push(new ArticleCategory('category.blueray', {
      label: 'filtersearch.filter.term.type.media',
      position: 1,
      type: 'MEDIA',
      filterGroupType: 'CHECKBOX_EXPANDABLE',
      sortBy: 'COUNT_DESC',
      filterResultItems: [
        this.createFilterTerm('0062'),
        this.createFilterTerm('0077'),
        this.createFilterTerm('0078'),
        this.createFilterTerm('0081'),
        this.createFilterTerm('0083'),
        this.createFilterTerm('0087'),
        this.createFilterTerm('0090'),
        this.createFilterTerm('0091'),
        this.createFilterTerm('0092')
      ]
    }))

    this.categories.push(new ArticleCategory('category.ebooks_audiobooks', {
      label: 'filtersearch.filter.term.type.genre',
      position: 6,
      type: 'GENRE',
      filterGroupType: 'CHECKBOX_EXPANDABLE',
      sortBy: 'COUNT_DESC',
      filterResultItems: [
        this.createFilterTerm('601'),
        this.createFilterTerm('602'),
        this.createFilterTerm('603'),
        this.createFilterTerm('604'),
        this.createFilterTerm('605'),
        this.createFilterTerm('606'),
        this.createFilterTerm('607'),
        this.createFilterTerm('608'),
        this.createFilterTerm('609'),
        this.createFilterTerm('610'),
        this.createFilterTerm('620'),
        this.createFilterTerm('621'),
        this.createFilterTerm('622'),
        this.createFilterTerm('623'),
        this.createFilterTerm('624'),
        this.createFilterTerm('625'),
        this.createFilterTerm('626'),
        this.createFilterTerm('627'),
        this.createFilterTerm('628'),
        this.createFilterTerm('629'),
        this.createFilterTerm('640'),
        this.createFilterTerm('641'),
        this.createFilterTerm('642'),
        this.createFilterTerm('643'),
        this.createFilterTerm('644'),
        this.createFilterTerm('645'),
        this.createFilterTerm('646'),
        this.createFilterTerm('650'),
        this.createFilterTerm('651'),
        this.createFilterTerm('652'),
        this.createFilterTerm('653'),
        this.createFilterTerm('654'),
        this.createFilterTerm('655'),
        this.createFilterTerm('660'),
        this.createFilterTerm('661'),
        this.createFilterTerm('662'),
        this.createFilterTerm('670'),
        this.createFilterTerm('671'),
        this.createFilterTerm('672'),
        this.createFilterTerm('680'),
        this.createFilterTerm('681'),
        this.createFilterTerm('682'),
        this.createFilterTerm('699'),
        this.createFilterTerm('904'),
        this.createFilterTerm('906')
      ]
    }))

    this.categories.push(new ArticleCategory('category.kids_and_family', {
      label: 'filtersearch.filter.term.type.genre',
      position: 1,
      type: 'GENRE',
      filterGroupType: 'CHECKBOX_EXPANDABLE',
      sortBy: 'COUNT_DESC',
      filterResultItems: [
        this.createFilterTerm('301'),
        this.createFilterTerm('302'),
        this.createFilterTerm('303'),
        this.createFilterTerm('304')
      ]
    }))

    this.categories.push(new ArticleCategory('category.merchandise', {
      label: 'filtersearch.filter.term.type.genre',
      position: 1,
      type: 'GENRE',
      filterGroupType: 'CHECKBOX_EXPANDABLE',
      sortBy: 'COUNT_DESC',
      filterResultItems: [
        this.createFilterTerm('901'),
        this.createFilterTerm('902'),
        this.createFilterTerm('903'),
        this.createFilterTerm('904'),
        this.createFilterTerm('905'),
        this.createFilterTerm('906'),
        this.createFilterTerm('999')
      ]
    }))

    this.categories.push(new ArticleCategory('category.clothing_accessories', {
      label: 'filtersearch.filter.term.type.media',
      position: 6,
      type: 'MEDIA',
      filterGroupType: 'CHECKBOX_EXPANDABLE',
      sortBy: 'COUNT_DESC',
      filterResultItems: [
        this.createFilterTerm('0079'),
        this.createFilterTerm('0400'),
        this.createFilterTerm('0401'),
        this.createFilterTerm('0402'),
        this.createFilterTerm('0403'),
        this.createFilterTerm('0404'),
        this.createFilterTerm('0405'),
        this.createFilterTerm('0406'),
        this.createFilterTerm('0407'),
        this.createFilterTerm('0408'),
        this.createFilterTerm('0409'),
        this.createFilterTerm('0410'),
        this.createFilterTerm('0411'),
        this.createFilterTerm('0412'),
        this.createFilterTerm('0413'),
        this.createFilterTerm('0414'),
        this.createFilterTerm('0415'),
        this.createFilterTerm('0416'),
        this.createFilterTerm('0417'),
        this.createFilterTerm('0418'),
        this.createFilterTerm('0419'),
        this.createFilterTerm('0420'),
        this.createFilterTerm('0421'),
        this.createFilterTerm('0422'),
        this.createFilterTerm('0423'),
        this.createFilterTerm('0424'),
        this.createFilterTerm('0425'),
        this.createFilterTerm('0426'),
        this.createFilterTerm('0427'),
        this.createFilterTerm('0428'),
        this.createFilterTerm('0429'),
        this.createFilterTerm('0430'),
        this.createFilterTerm('0431'),
        this.createFilterTerm('0432'),
        this.createFilterTerm('0499')
      ]
    }))

    this.categories.push(new ArticleCategory('category.interactive', {
      label: 'filtersearch.filter.term.type.genre',
      position: 0,
      type: 'GENRE',
      filterGroupType: 'CHECKBOX_EXPANDABLE',
      sortBy: 'COUNT_DESC',
      filterResultItems: [
        this.createFilterTerm('501'),
        this.createFilterTerm('502'),
        this.createFilterTerm('503'),
        this.createFilterTerm('504'),
        this.createFilterTerm('505'),
        this.createFilterTerm('506'),
        this.createFilterTerm('507'),
        this.createFilterTerm('508'),
        this.createFilterTerm('509'),
        this.createFilterTerm('510'),
        this.createFilterTerm('511'),
        this.createFilterTerm('512'),
        this.createFilterTerm('513'),
        this.createFilterTerm('514'),
        this.createFilterTerm('515'),
        this.createFilterTerm('516'),
        this.createFilterTerm('517'),
        this.createFilterTerm('518'),
        this.createFilterTerm('519'),
        this.createFilterTerm('520'),
        this.createFilterTerm('521'),
        this.createFilterTerm('522'),
        this.createFilterTerm('523'),
        this.createFilterTerm('524'),
        this.createFilterTerm('525'),
        this.createFilterTerm('526'),
        this.createFilterTerm('527'),
        this.createFilterTerm('531'),
        this.createFilterTerm('532'),
        this.createFilterTerm('533'),
        this.createFilterTerm('534'),
        this.createFilterTerm('535'),
        this.createFilterTerm('536'),
        this.createFilterTerm('537'),
        this.createFilterTerm('541'),
        this.createFilterTerm('542'),
        this.createFilterTerm('543'),
        this.createFilterTerm('544'),
        this.createFilterTerm('545'),
        this.createFilterTerm('546'),
        this.createFilterTerm('547'),
        this.createFilterTerm('548'),
        this.createFilterTerm('549'),
        this.createFilterTerm('550'),
        this.createFilterTerm('551'),
        this.createFilterTerm('552'),
        this.createFilterTerm('553'),
        this.createFilterTerm('554'),
        this.createFilterTerm('555'),
        this.createFilterTerm('561'),
        this.createFilterTerm('562'),
        this.createFilterTerm('563'),
        this.createFilterTerm('564'),
        this.createFilterTerm('565'),
        this.createFilterTerm('566'),
        this.createFilterTerm('567'),
        this.createFilterTerm('568'),
        this.createFilterTerm('569'),
        this.createFilterTerm('570'),
        this.createFilterTerm('571'),
        this.createFilterTerm('572'),
        this.createFilterTerm('573'),
        this.createFilterTerm('574'),
        this.createFilterTerm('575'),
        this.createFilterTerm('576'),
        this.createFilterTerm('577'),
        this.createFilterTerm('578'),
        this.createFilterTerm('579'),
        this.createFilterTerm('581'),
        this.createFilterTerm('582'),
        this.createFilterTerm('583'),
        this.createFilterTerm('584'),
        this.createFilterTerm('585'),
        this.createFilterTerm('586'),
        this.createFilterTerm('587'),
        this.createFilterTerm('588'),
        this.createFilterTerm('589'),
        this.createFilterTerm('590'),
        this.createFilterTerm('591'),
        this.createFilterTerm('599')
      ]
    }))

    this.categories.push(new ArticleCategory('category.games_software', {
      label: 'filtersearch.filter.term.type.media',
      position: 2,
      type: 'MEDIA',
      filterGroupType: 'CHECKBOX_EXPANDABLE',
      sortBy: 'COUNT_DESC',
      filterResultItems: [
        this.createFilterTerm('0016'),
        this.createFilterTerm('0017'),
        this.createFilterTerm('0030'),
        this.createFilterTerm('0060'),
        this.createFilterTerm('0066'),
        this.createFilterTerm('0200'),
        this.createFilterTerm('0201'),
        this.createFilterTerm('0202'),
        this.createFilterTerm('0203'),
        this.createFilterTerm('0204'),
        this.createFilterTerm('0205'),
        this.createFilterTerm('0206'),
        this.createFilterTerm('0207'),
        this.createFilterTerm('0208'),
        this.createFilterTerm('0209'),
        this.createFilterTerm('0210'),
        this.createFilterTerm('0211'),
        this.createFilterTerm('0212'),
        this.createFilterTerm('0213'),
        this.createFilterTerm('0214'),
        this.createFilterTerm('0215'),
        this.createFilterTerm('0216'),
        this.createFilterTerm('0217'),
        this.createFilterTerm('0218'),
        this.createFilterTerm('0219'),
        this.createFilterTerm('0220'),
        this.createFilterTerm('0221'),
        this.createFilterTerm('0222'),
        this.createFilterTerm('0223'),
        this.createFilterTerm('0224'),
        this.createFilterTerm('0225'),
        this.createFilterTerm('0226'),
        this.createFilterTerm('0227'),
        this.createFilterTerm('0228'),
        this.createFilterTerm('0229'),
        this.createFilterTerm('0230'),
        this.createFilterTerm('0231'),
        this.createFilterTerm('0240')
      ]
    }))

    this.categories.push(new ArticleCategory('category.other', {
      label: 'filtersearch.filter.term.type.media',
      position: 0,
      type: 'MEDIA',
      filterGroupType: 'CHECKBOX_EXPANDABLE',
      sortBy: 'COUNT_DESC',
      filterResultItems: [
        this.createFilterTerm('0010'),
        this.createFilterTerm('0011'),
        this.createFilterTerm('0013'),
        this.createFilterTerm('0014'),
        this.createFilterTerm('0032'),
        this.createFilterTerm('0070'),
        this.createFilterTerm('0099'),
        this.createFilterTerm('0100')
      ]
    }))
  }
}
</script>
