import SearchViewState from '../../domain/SearchViewState'
import { logger } from '../../util/Logger'
import FieldTermAutcompleteRequest from '../../domain/dto/FieldTermAutocompleteRequest'
import FilterSearchRequest from '../../domain/dto/FilterSearchRequest'
import forEach from 'lodash-es/forEach'
import FilterTerm from '../../domain/FilterTerm'
import FilterTerms from '../../domain/FilterTerms'
import FieldTerm from '../../domain/FieldTerm'
import FieldTerms from '../../domain/FieldTerms'
import isArray from 'lodash-es/isArray'
import filter from 'lodash-es/filter'
import find from 'lodash-es/find'
import map from 'lodash-es/map'
import remove from 'lodash-es/remove'
import property from 'lodash-es/property'
import FilterTermsDTO from '../../domain/dto/FilterTermsDTO'
import FieldTermsDTO from '../../domain/dto/FieldTermsDTO'
import OrderBy from '../../domain/OrderBy'

// initial state
const state = {
  searchViewState: new SearchViewState()
}

// getters
const getters = {
  getFilterTermByType: (state) => (type) => {
    for (let i = 0; i < state.searchViewState.filterTerms.length; i++) {
      const filterTerms = state.searchViewState.filterTerms[i]
      if (filterTerms.type === type) {
        return filterTerms
      }
    }
    return null
  },
  getFieldTermByType: (state) => (type) => {
    for (let i = 0; i < state.searchViewState.fieldTerms.length; i++) {
      const fieldTerms = state.searchViewState.fieldTerms[i]
      if (fieldTerms.type === type) {
        return fieldTerms
      }
    }
    return null
  },
  getNewFilterSearchQuery: (state, getters) => {
    return getters.getFilterSearchQuery(0, state.searchViewState.itemCount, true)
  },
  getMoreFilterSearchQuery: (state, getters) => {
    const offset = state.searchViewState.itemsDisplayedCount
    const limit = state.searchViewState.itemsLeft() < state.searchViewState.itemCount ? state.searchViewState.itemsLeft() : state.searchViewState.itemCount
    return getters.getFilterSearchQuery(offset, limit, false)
  },
  getFilterSearchQuery: (state, getters) => (offset, limit, analyzeFilters = true) => {
    const filterTermDTOs = getters.getFilterTermDTOs(state.searchViewState.filterTerms)
    const fieldTermDTOs = getters.getFieldTermDTOs(state.searchViewState.fieldTerms)

    let reverseOrder = state.searchViewState.isReverseOrder
    if (state.searchViewState.orderBy === OrderBy.released) {
      reverseOrder = !reverseOrder
    }
    return new FilterSearchRequest(state.searchViewState.searchTerm, state.searchViewState.isAvailable, state.searchViewState.orderBy,
      reverseOrder, offset, limit, analyzeFilters, fieldTermDTOs, filterTermDTOs,
      state.searchViewState.releaseDateFrom, state.searchViewState.releaseDateTo)
  },
  /**
     *
     * @param {Object} state
     * @param {Object} getters
     * @param {string} fieldTermType
     * @param {string} fieldSearchTerm
     * @return {FieldTermAutocompleteRequest}
     */
  getNewFieldAutocompleteTermQuery: (state, getters, fieldTermType, fieldSearchTerm) => {
    return new FieldTermAutcompleteRequest(fieldTermType, fieldSearchTerm, '', state.searchViewState.isAvailable, [], [], null, null)
  },
  getFieldAutocompleteTermQuery: (state, getters) => (fieldTermType, fieldSearchTerm) => {
    const filterTermDTOs = getters.getFilterTermDTOs(state.searchViewState.filterTerms)
    const fieldTermDTOs = getters.getFieldTermDTOs(state.searchViewState.fieldTerms)

    // now filter out the fieldTerms of the type the request is for
    remove(fieldTermDTOs, (aFieldTermsDTO) => {
      return aFieldTermsDTO.type === fieldTermType
    })

    return new FieldTermAutcompleteRequest(fieldTermType, fieldSearchTerm, state.searchViewState.searchTerm, state.searchViewState.isAvailable, fieldTermDTOs, filterTermDTOs,
      state.searchViewState.releaseDateFrom, state.searchViewState.releaseDateTo)
  },
  getFilterTermDTOs: () => (filterTerms) => {
    const filterTermDTOS = []
    forEach(filterTerms, /** @param {FilterTerms} aFilterTermGroup */
      (aFilterTermGroup) => {
        const activeFilterTerms = filter(aFilterTermGroup.terms,
          /**
                 * @param {FilterTerm} aFilterTermTerm
                 * @return {boolean}
                 * */
          (aFilterTermTerm) => {
            return aFilterTermTerm.active
          })

        const onlyTerms = map(activeFilterTerms, property('term'))

        if (onlyTerms.length > 0) {
          const fieldTermDTO = new FilterTermsDTO(aFilterTermGroup.type, onlyTerms)
          filterTermDTOS.push(fieldTermDTO)
        }
      })
    return filterTermDTOS
  },
  getFieldTermDTOs: () => (fieldTerms) => {
    const fieldTermDTOs = []
    forEach(fieldTerms, /** @param {FieldTerms} aFieldTermGroup */
      (aFieldTermGroup) => {
        if (aFieldTermGroup.terms.length > 0) {
          const onlyTerms = map(aFieldTermGroup.terms, property('term'))
          const fieldTermDTO = new FieldTermsDTO(aFieldTermGroup.type, onlyTerms)
          fieldTermDTOs.push(fieldTermDTO)
        }
      })
    return fieldTermDTOs
  }
}

/**
 * @typedef {Object} VueExContext
 * @property {Object} state
 * @property {Object} rootState
 * @property {Object} commit
 * @property {Object} dispatch
 * @property {Object} getters
 * @property {Object} rootGetters
 */

// actions
const actions = {
  /**
     * @param {Object} context
     * @param {SearchResult} searchResult
     */
  setNewSearchRespone: (context, searchResult) => {
    context.commit('setTotalResults', searchResult.total)
    // this must be a new search
    context.commit('setItemsDisplayedCount', searchResult.resultItems.length)
    if (isArray(searchResult.filterGroupItems) && searchResult.filterGroupItems.length > 0) {
      context.commit('setFilterGroupItems', searchResult.filterGroupItems)
    }

    if (isArray(searchResult.fieldGroupItems) && searchResult.fieldGroupItems.length > 0) {
      context.commit('setFieldGroupItems', searchResult.fieldGroupItems)
    }
  },
  setMoreSearchResponse: (context, searchResult) => {
    context.commit('addItemsDisplayedCount', searchResult.resultItems.length)
  },
  resetFilters (context) {
    context.commit('resetFilters')
  },
  setSearchTerm (context, searchTerm) {
    context.commit('setSearchTerm', searchTerm)
  },
  clearSearchParameterAndSetSearchTerm (context, searchTerm) {
    context.commit('setSearchTerm', searchTerm)
    return context.dispatch('clearSearchParameter')
  },
  clearSearchParameter (context) {
    logger.info('clearSearchParameter')
    context.commit('setFilterGroupItems', [])
    context.commit('setFieldGroupItems', [])
  },
  forceNewSearch (context) {
    context.commit('setForceNewSearch', true)
  },
  setForceNewSearch (context, forceNewSearch) {
    context.commit('setForceNewSearch', forceNewSearch)
  },
  setDisplaySearchParameterArea (context, displaySearchParameterArea) {
    context.commit('setDisplaySearchParameterArea', displaySearchParameterArea)
  },

  setFilterTermsBySearchFilterGroupItem (context, searchFilterGroupItem) {
    context.commit('setSearchTerm', '')
    return context.dispatch('clearSearchParameter')
      .then(() => {
        context.commit('setFilterGroupItems', [searchFilterGroupItem])
      })
  },
  addAutocompleteFieldTerm (context, params) {
    context.commit('setSearchTerm', '')
    return context.dispatch('clearSearchParameter')
      .then(() => {
        const newFieldGroupItem = {
          label: params.heading,
          type: params.type,
          position: 0,
          fieldGroupType: '',
          fieldResultItems: [{
            label: params.label,
            term: params.term
          }]
        }
        context.commit('setFieldGroupItems', [newFieldGroupItem])
      })
  },
  updateFilterTermActive: (context, params) => {
    context.commit('updateFilterTermActive', params)
  },
  addFieldTerm (context, params) {
    context.commit('addFieldTerm', params)
  },
  removeFieldTerm (context, params) {
    context.commit('removeFieldTerm', params)
  },
  toggleReverseOrder (context) {
    context.commit('toggleReverseOrder')
  },
  setOrderBy (context, orderBy) {
    context.commit('setOrderBy', orderBy)
  },
  /**
     * @param {Object} context
     * @param {String|null} date
     */
  setReleaseDateFrom (context, date) {
    context.commit('setReleaseDateFrom', date)
  },
  /**
     * @param {Object} context
     * @param {String|null} date
     */
  setReleaseDateTo (context, date) {
    context.commit('setReleaseDateTo', date)
  }
}

// mutations
const mutations = {
  toggleReverseOrder (state) {
    state.searchViewState.isReverseOrder = !state.searchViewState.isReverseOrder
  },
  setOrderBy (state, orderBy) {
    state.searchViewState.orderBy = orderBy
    // reset reverseOrder
    state.searchViewState.isReverseOrder = state.searchViewState._defaultSearchState.isReverseOrder
  },
  /**
     * @param {Object} state
     * @param {String|null} date
     */
  setReleaseDateFrom (state, date) {
    state.searchViewState.releaseDateFrom = date
  },
  /**
     * @param {Object} state
     * @param {String|null} date
     */
  setReleaseDateTo (state, date) {
    state.searchViewState.releaseDateTo = date
  },
  resetFilters (state) {
    state.searchViewState.releaseDateFrom = null
    state.searchViewState.releaseDateTo = null

    // set all filter terms to unactive

    state.searchViewState.filterTerms.forEach(function (filterTerms) {
      // select active terms due to vuex performance reasons
      const activeTerms = filter(filterTerms.terms, (aFilterTerm) => {
        return aFilterTerm.active === true
      })

      activeTerms.forEach(function (term) {
        term.active = false
      })
    })

    // remove field terms
    state.searchViewState.fieldTerms.forEach(function (fieldTerms) {
      fieldTerms.terms = []
    })
  },
  addItemsDisplayedCount (state, itemsDisplayedCount) {
    state.searchViewState.itemsDisplayedCount = state.searchViewState.itemsDisplayedCount + itemsDisplayedCount
  },
  setItemsDisplayedCount (state, itemsDisplayedCount) {
    state.searchViewState.itemsDisplayedCount = itemsDisplayedCount
  },
  setTotalResults (state, totalResults) {
    state.searchViewState.totalResults = totalResults
  },
  setForceNewSearch (state, force) {
    state.searchViewState.forceNewSearch = force
  },
  setDisplaySearchParameterArea (state, displaySearchParameterArea) {
    state.searchViewState.displaySearchParameterArea = displaySearchParameterArea
  },
  setSearchTerm (state, term) {
    state.searchViewState.searchTerm = term
  },
  updateFilterTermActive (state, params) {
    const filterTerms = this.getters.getFilterTermByType(params.type)
    if (filterTerms != null) {
      const aTerm = find(filterTerms.terms, (t) => {
        return t.term === params.term
      })
      aTerm.active = params.value
    } else {
      logger.error('Could not update FilterTerm: was null')
    }
  },
  addFieldTerm (state, params) {
    this.getters.getFieldTermByType(params.type).terms.push(new FieldTerm(params.term, params.label))
    logger.info('added fieldTerm: ' + params.label)
  },
  removeFieldTerm (state, params) {
    this.getters.getFieldTermByType(params.type).terms.splice(params.id, 1)
  },
  /**
     * Throws the
     * @param {Object} state
     * @param {SearchFilterGroupItem[]} filterGroupItems
     */
  setFilterGroupItems (state, filterGroupItems) {
    const newFilterTerms = []
    forEach(filterGroupItems, /** @param {SearchFilterGroupItem} aGroupItem */
      (aGroupItem) => {
        const terms = []
        forEach(aGroupItem.filterResultItems, /** @param {SearchFilterResultItem} aTerm */
          (aTerm) => {
            const aFilterTerm = new FilterTerm(aTerm.label, aTerm.term, aTerm.count, aTerm.disabled, aTerm.active)
            terms.push(aFilterTerm)
          })

        const filterTerms = new FilterTerms(aGroupItem.type, aGroupItem.label, aGroupItem.position, aGroupItem.filterGroupType, aGroupItem.sortBy, terms)
        newFilterTerms.push(filterTerms)
      })
    state.searchViewState.filterTerms = newFilterTerms
  },
  /**
     * Throws the
     * @param {Object} state
     * @param {SearchFieldGroupItem[]} fieldGroupItems
     */
  setFieldGroupItems (state, fieldGroupItems) {
    const newFieldTerms = []
    forEach(fieldGroupItems, /** @param {SearchFieldGroupItem} aGroupItem */
      (aGroupItem) => {
        const terms = []
        forEach(aGroupItem.fieldResultItems, /** @param {SearchFieldResultItem} aResultItem */
          (aResultItem) => {
            const aFieldTerm = new FieldTerm(aResultItem.term, aResultItem.label)
            terms.push(aFieldTerm)
          })

        const fieldTerms = new FieldTerms(aGroupItem.type, aGroupItem.label, aGroupItem.position, aGroupItem.fieldGroupType, terms)
        newFieldTerms.push(fieldTerms)
      })
    state.searchViewState.fieldTerms = newFieldTerms
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
