<template>
    <footer class="footer footer-black footer-big">
        <div class="container">
            <ul class="float-left">
                <li>
                    <router-link to="/imprint">{{ $t('imprint.title') }}</router-link>
                </li>
                <li>
                    <router-link to="/data-privacy">{{ $t('data_privacy.title') }}</router-link>
                </li>
                <li>
                    <a href="https://www.offiziellecharts.de/" target="_blank" :title="$t('charts.hint')">{{ $t('charts.title') }}</a>
                </li>
            </ul>
            <div class="copyright float-right">
                &#xA9; Copyright and produced by PHONONET GmbH, all rights reserved
            </div>
        </div>
    </footer>
</template>

<script>
export default {
  name: 'FooterComponent'
}
</script>
