<template>
    <div class="landing-page">
        <div class="moving-background-container"
             v-if="loaded && !isIOS">
            <div class="moving-background-overlay"></div>
            <div class="moving-background">

                <ul class="image-grid">
                    <!-- eslint-disable-next-line vue/require-v-for-key -->
                    <li class="image-grid-item-container"
                        v-for="image in imageStream">
                        <img class="image-grid-item"
                             :src="image">
                    </li>
                </ul>

            </div>
        </div>
        <div class="page-header header-filter">
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <h1 class="title">{{ $t('landing_page.greeting_header') }}</h1>
                        <h4>{{ $t('landing_page.greeting_message') }}</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import filter from 'lodash-es/filter'
import shuffle from 'lodash-es/shuffle'
import isString from 'lodash-es/isString'
import { instance as FilterSearchService } from '../services/FilterSearchService'
import FilterSearchRequest from '../domain/dto/FilterSearchRequest'
import OrderBy from '../domain/OrderBy'

export default {
  name: 'LandingPageComponent',
  data () {
    return {
      numImageStream: 200,
      numImages: 20,
      loaded: false,
      images: []
    }
  },
  created () {
    const request = new FilterSearchRequest('', true, OrderBy.released, true, 0, 100, false, [], [], null, null)
    FilterSearchService.send(request)
      .then((searchResult) => {
        // the result should always be shuffled
        const resultWithCovers = shuffle(filter(searchResult.resultItems, (aResultItem) => {
          return isString(aResultItem.artwork) && aResultItem.artwork.startsWith('https')
        }))

        const wallImages = []
        for (let i = 0; i < this.numImages && i + 1 < resultWithCovers.length; i++) {
          const mediumCover = resultWithCovers[i].artwork.replace('0080px', '0300px')
          wallImages.push(mediumCover)
        }
        this.images = wallImages
        this.loaded = true
      })
  },
  computed: {
    imageStream () {
      const res = []
      for (let i = 0; i < this.numImageStream; i++) {
        const j = i % this.numImages
        res.push(this.images[j])
      }
      return res
    },
    isIOS () {
      return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
    }
  }
}
</script>

<style scoped>
    @media screen and (max-width: 20rem) {
        .landing-page > .page-header > .container {
            margin-top: 150px;
        }
    }
    @media screen and (max-width: 27rem) {
        .landing-page > .page-header > .container {
            margin-top: 100px;
        }
    }

    @media screen and (min-width: 760px) and (max-width: 996px) {
        .landing-page > .page-header > .container {
            margin-top: 100px;
        }
    }
</style>
