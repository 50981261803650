<template>
    <div class="form-inline ml-lg-auto ml-3 mr-0"
         id="autocomplete-container">
        <div class="form-group has-white-lg pt-0 mb-0">
            <div :class="[autoCompletionEnabled ? 'dropdown focusable-dropdown' : '']">
                <input type="text"
                       class="form-control"
                       v-model="searchTerm"
                       :placeholder="$t('search.placeholder')"
                       @keyup.down="downPressed"
                       @keyup.up="upPressed"
                       @keyup.enter.prevent="enterPressed"
                       @focus="checkIfShowDropdown"
                       aria-haspopup="true"
                       :aria-expanded="showDropdown ? 'true' : 'false'"
                       autocomplete="off">
                <i class="material-icons search-clear-icon"
                   @click="clearSearch"
                   v-show="searchTerm !== ''">
                    clear
                </i>
                <div class="dropdown-menu"
                     :class="{ show : showDropdown }"
                     v-if="autoCompletionEnabled">
                    <div class="loading loading-small"
                         v-show="loadingSearchAutoComplete">
                    </div>
                    <div class="dropdown-divider mt-4"
                         v-show="loadingSearchAutoComplete && filterSearchAutoCompleteResult"></div>

                    <div v-if="filterSearchAutoCompleteResult">
                        <div v-for="fieldTermGroup in filterSearchAutoCompleteResult.fieldTermGroups"
                             :key="fieldTermGroup.fieldTermType">
                            <h6 class="dropdown-header">
                                {{ fieldTermGroup.label }}
                            </h6>
                            <div class="dropdown-item"
                                 v-for="item in fieldTermGroup.autoCompleteItems"
                                 :class="{ active: item.index === currentFocus }"
                                 :key="item.term"
                                 @click="addAutocompleteFieldTerm(fieldTermGroup.fieldTermType, item, 'search.suggestions.header.' + fieldTermGroup.label)"
                                 @mouseover="currentFocus = item.index">
                                {{ item.label }}
                            </div>
                            <div class="dropdown-divider"></div>
                        </div>

                        <div class="dropdown-item"
                             @click="search"
                             @mouseover="currentFocus = autoCompleteItemsCount"
                             :class="{ active: autoCompleteItemsCount === currentFocus }">
                            {{ filterSearchAutoCompleteResult.allResultsLabel }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <button type="button"
                @click="search"
                class="btn btn-white btn-raised btn-fab btn-round">
            <i class="material-icons">search</i>
        </button>
    </div>
</template>

<script>
import debounce from 'lodash-es/debounce'
import AutoCompleteService from '../services/AutoCompleteService'
import { logger } from '../util/Logger'
import { EventBus } from '../services/EventBus'
import Events from '../domain/Events'
import AutocompleteRequest from '../domain/dto/AutocompleteRequest'
import config from './../config'

export default {
  name: 'AutoCompleterComponent',
  data () {
    return {
      autoCompletionEnabled: config['enable-auto-complete'],
      showDropdown: false,
      autoCompleteItemsCount: 0,
      currentFocus: -1,
      searchTerm: '',
      lastSearch: '',
      filterSearchAutoCompleteResult: null,
      loadingSearchAutoComplete: false,
      minimumCharacters: 3
    }
  },
  watch: {
    searchTerm () {
      if (this.autoCompletionEnabled) {
        this.showDropdown = this.searchTerm.length >= this.minimumCharacters
        this.loadingSearchAutoComplete = true
        this.debouncedGetSuggestions()
      }
    },
    showDropdown () {
      if (!this.showDropdown) { // reset focus on close
        this.currentFocus = -1
      }
    }
  },
  created () {
    this.debouncedGetSuggestions = debounce(this.getSuggestions, 400)
  },
  mounted () {
    if (this.autoCompletionEnabled) {
      $('html').on('click', (e) => {
        const $target = $(e.target)
        if ($target.closest('.focusable-dropdown').length === 0) {
          this.showDropdown = false
        }
      })
    }
  },
  destroyed () {
    $(document).off('click')
  },
  methods: {
    getSuggestions () {
      if (this.searchTerm.length < this.minimumCharacters) {
        this.filterSearchAutoCompleteResult = null
        this.loadingSearchAutoComplete = false
        return
      }
      const thisSearch = this.searchTerm
      this.lastSearch = thisSearch
      const autocompleteRequest = new AutocompleteRequest(this.searchTerm, this.$store.state.search.searchViewState.isAvailable)
      AutoCompleteService.getSearchAutoCompletion(autocompleteRequest)
        .then((filterSearchAutoCompleteResult) => {
          this.filterSearchAutoCompleteResult = filterSearchAutoCompleteResult
          this.addIndexToAutocompleteItems()
        })
        .catch((jqXHR, textStatus, errorThrown) => {
          this.filterSearchAutoCompleteResult = null
          logger.error('Could not get autocomplete suggestions.', jqXHR, textStatus, errorThrown)
        })
        .finally(() => {
          if (this.lastSearch === thisSearch) { // only hide on newest result
            this.loadingSearchAutoComplete = false
          }
        })
    },
    addIndexToAutocompleteItems () {
      let curInd = 0
      for (const group of this.filterSearchAutoCompleteResult.fieldTermGroups) {
        for (const autoCompleteItem of group.autoCompleteItems) {
          autoCompleteItem.index = curInd++
        }
      }
      this.autoCompleteItemsCount = curInd
    },
    search () {
      logger.debug('AutoCompleterComponent.search()')
      this.$store.dispatch('clearSearchParameterAndSetSearchTerm', this.searchTerm)
        .then(() => {
          return this.$store.dispatch('forceNewSearch')
        })
        .then(() => {
          // route to search view
          this.$router.push({ path: '/search', query: { q: encodeURIComponent(this.searchTerm) } })
          this.showDropdown = false
          // if the SearchComponent doesn't exist yet, it will not receive this Event
          EventBus.$emit(Events.TRIGGER_NEW_SEARCH)
          this.$emit('hideNavbar')
        })
    },
    addAutocompleteFieldTerm (type, item, heading) {
      this.searchTerm = ''
      this.$store.dispatch('addAutocompleteFieldTerm', {
        type: type,
        term: item.term,
        label: item.label,
        heading: heading
      }).then(() => {
        return this.$store.dispatch('forceNewSearch')
      }).then(() => {
        EventBus.$emit(Events.TRIGGER_NEW_SEARCH)
        this.filterSearchAutoCompleteResult = null
        this.$emit('hideNavbar')
        this.$router.push({ path: '/search', query: { q: '' } })
      })
    },
    checkIfShowDropdown () {
      if (this.searchTerm.length >= this.minimumCharacters) {
        this.showDropdown = true
      }
    },
    upPressed () {
      if (this.currentFocus >= 0) {
        this.currentFocus--
      }
    },
    downPressed () {
      if (this.currentFocus < this.autoCompleteItemsCount) {
        this.currentFocus++
      }
    },
    enterPressed () {
      if (this.currentFocus === this.autoCompleteItemsCount || this.currentFocus === -1) {
        this.search()
      } else {
        for (const fieldTermGroup of this.filterSearchAutoCompleteResult.fieldTermGroups) {
          for (const autoCompleteItem of fieldTermGroup.autoCompleteItems) {
            if (autoCompleteItem.index === this.currentFocus) {
              this.addAutocompleteFieldTerm(fieldTermGroup.fieldTermType, autoCompleteItem, 'search.suggestions.header.' + fieldTermGroup.label)
            }
          }
        }
      }
    },
    clearSearch () {
      this.searchTerm = ''
    }
  }
}
</script>
