/**
 * data transfer object for FilterTerms
 */
export default class FilterTermsDTO {
  /**
     * constructs FilterTermsDTO
     * @param {FilterTermType} type
     * @param {FilterTerm[]} filterTerms
     */
  constructor (type, filterTerms) {
    this.type = type

    this.terms = filterTerms
  }
}
