import * as Log4js from 'log4javascript'
import config from '../config'

export const logger = Log4js.getLogger()
if (config.debug) {
  const browserConsoleAppender = new Log4js.BrowserConsoleAppender()
  const patternLayout = new Log4js.PatternLayout('%d{HH:mm:ss,SSS} %p %m')
  browserConsoleAppender.setLayout(patternLayout)
  logger.addAppender(browserConsoleAppender)
  logger.setLevel(Log4js.Level.ALL)
} else {
  const ajaxAppender = new Log4js.AjaxAppender(config['api-baseurl'] + '/log')
  ajaxAppender.setSendAllOnUnload(true)
  logger.addAppender(ajaxAppender)
  logger.setLevel(Log4js.Level.WARN)
}
