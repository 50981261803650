import merge from 'lodash-es/merge'
import cloneDeep from 'lodash-es/cloneDeep'
import OrderBy from './OrderBy'

/**
 *
 */
export default class SearchViewState {
  /**
     * @param {Object} opts
     */
  constructor (opts) {
    this._defaultSearchState = {
      forceNewSearch: true,
      displaySearchParameterArea: true,
      searchTerm: '',
      filterTerms: [],
      fieldTerms: [],
      isReverseOrder: false,
      orderBy: OrderBy.score,
      releaseDateFrom: null,
      releaseDateTo: null,
      isAvailable: true
    }

    const options = merge(cloneDeep(this._defaultSearchState), opts)

    /**
         * this flag determines, if a new search is suppose to be executed when the SearchComponent gets created()
         * @type {boolean}
         */
    this.forceNewSearch = options.forceNewSearch

    /**
         * This flag determines, whether the search-parameters (date-range, filterTerms and fieldTerms) on the right side of the search-result area get displayed
         * @type {*|boolean}
         */
    this.displaySearchParameterArea = options.displaySearchParameterArea
    /**
         * @type {String}
         */
    this.searchTerm = options.searchTerm
    /**
         * The possible filter terms (includes active ones)
         * @type {FilterTerms[]}
         */
    this.filterTerms = options.filterTerms
    /**
         * The active field terms
         * @type {FieldTerms[]}
         */
    this.fieldTerms = options.fieldTerms
    /**
         * The total number of results the query could return
         * @type {Number}
         */
    this.totalResults = 0
    /**
         * The number of items currently being displayed
         * @type {Number}
         */
    this.itemsDisplayedCount = 0
    /**
         * The maximum number of results to retrieve for a single search
         * @type {Number}
         */
    this.itemCount = 50
    /**
         * The number of filters that will be displayed before a showAll-Link
         * @type {Number}
         */
    this.filterCheckboxExpendableCount = 10
    /**
         * The maximum number of filters that will be displayed in a filtergroup
         * @type {Number}
         */
    this.filterMaxCount = 400
    /**
         * Sort in reverse order?
         * @type {Boolean}
         */
    this.isReverseOrder = options.isReverseOrder
    /**
         * The parameter to sort the results by
         * @type {OrderBy}
         */
    this.orderBy = options.orderBy

    /**
         * Filter results beginning from this date
         * @type {String|null}
         */
    this.releaseDateFrom = options.releaseDateFrom
    /**
         * Filter results before this date
         * @type {String|null}
         */
    this.releaseDateTo = options.releaseDateTo

    /**
         * Filters results depending on if they are available
         * @type {boolean}
         */
    this.isAvailable = true
  }

  /**
     * @return {number} number of results left to fetch
     */
  itemsLeft () {
    return this.totalResults - this.itemsDisplayedCount
  }

  /**
     * @return {boolean} are there more results to load
     */
  hasMoreResults () {
    return this.itemsDisplayedCount < this.totalResults
  }
}
