export default class ArticleCategory {
  /**
     * @param {String} label
     * @param {SearchFilterGroupItem} searchFilterGroupItem
     */
  constructor (label, searchFilterGroupItem) {
    /**
         * @type {String}
         */
    this.label = label
    /**
         * Name to display in the view
         * @type {SearchFilterGroupItem}
         */
    this.searchFilterGroupItem = searchFilterGroupItem
  }
}
