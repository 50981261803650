/**
 * Wrapper for array of FieldTerms
 */
export default class FieldTerms {
  /**
     * @param {String} type
     * @param {String} label
     * @param {number} position
     * @param {String} fieldGroupType
     * @param {FieldTerm[]} terms
     */
  constructor (type, label, position, fieldGroupType, terms) {
    /**
         * @type {String}
         */
    this.type = type
    /**
         * Name to display in the view
         * @type {String}
         */
    this.label = label
    /**
         * Array of FieldTerms
         * @type {FieldTerm[]}
         */
    this.terms = terms

    /**
         * @type {number}
         */
    this.position = position

    /**
         * @type {String}
         */
    this.fieldGroupType = fieldGroupType
  }
}
