<template>
    <b-dropdown-item @click.prevent="searchCategory()">{{ $t(label) }}</b-dropdown-item>
</template>

<script>
import { EventBus } from '../services/EventBus'
import Events from '../domain/Events'

export default {
  name: 'CategoryComponent',
  props: ['label', 'searchFilterGroupItem'],
  methods: {
    searchCategory: function () {
      this.$store.dispatch('setFilterTermsBySearchFilterGroupItem', this.searchFilterGroupItem)
        .then(() => {
          return this.$store.dispatch('forceNewSearch')
        })
        .then(() => {
          return this.$store.dispatch('setDisplaySearchParameterArea', false)
        })
        .then(() => {
          EventBus.$emit(Events.TRIGGER_NEW_SEARCH)
          this.$router.push({ path: '/search', query: { q: '' } })
        })
    }
  }
}

</script>

<style scoped>

</style>
