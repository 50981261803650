import config from '../config'

/**
 * Service for autocompletion
 */
export default class AutoCompleteService {
  /**
     * @param {FieldTermAutocompleteRequest} fieldTermAutocompleteRequest
     * @return {Promise}
     */
  static getFieldTermSuggestion (fieldTermAutocompleteRequest) {
    const url = config['api-baseurl'] + '/api/autocomplete/fieldTerm'
    return new Promise((resolve, reject) => {
      $.ajax({
        url: url,
        dataType: 'json',
        method: 'POST',
        contentType: 'application/json',
        data: JSON.stringify(fieldTermAutocompleteRequest),
        processData: false
      }).done((data) => {
        resolve(data.fieldTermAutoCompleteResult.autoCompleteItems)
      })
        .fail((jqXHR, textStatus, errorThrown) => {
          reject(jqXHR, textStatus, errorThrown)
        })
    })
  }

  /**
     * @param {AutocompleteRequest} autocompleteRequest
     * @return {Promise}
     */
  static getSearchAutoCompletion (autocompleteRequest) {
    const url = config['api-baseurl'] + '/api/autocomplete/search'
    return new Promise((resolve, reject) => {
      $.ajax({
        url: url,
        dataType: 'json',
        method: 'POST',
        contentType: 'application/json',
        data: JSON.stringify(autocompleteRequest),
        processData: false
      }).done((data) => {
        resolve(data.filterSearchAutoCompleteResult)
      })
        .fail((jqXHR, textStatus, errorThrown) => {
          reject(jqXHR, textStatus, errorThrown)
        })
    })
  }
}
