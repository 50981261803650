<template>
    <div id="app-container">
        <div is="navbar-component"></div>
        <transition name="fade" appear>
        <router-view></router-view>
        </transition>
        <div is="footer-component"></div>
    </div>
</template>

<script>
import NavBarComponent from './NavBarComponent.vue'
import FooterComponent from './FooterComponent.vue'

export default {
  components: {
    'navbar-component': NavBarComponent,
    'footer-component': FooterComponent
  }
}
</script>

<style scoped>
    .fade-enter-active, .fade-leave-active {
        transition: opacity .2s;
    }
    .fade-enter, .fade-leave-to {
        opacity: 0;
    }
</style>
