import Vue from 'vue'
import VueRouter from 'vue-router'
import LandingPageComponent from '../components/LandingPageComponent.vue'

const ImprintComponent = () => import(/* webpackChunkName: 'imprint-component' */ '../components/ImprintComponent.vue')
const DataPrivacyComponent = () => import(/* webpackChunkName: 'privacy-component' */ '../components/DataPrivacyComponent.vue')
const ErrorComponent = () => import(/* webpackChunkName: 'error-component' */ '../components/ErrorComponent.vue')
const ProductDetailComponent = () => import(/* webpackChunkName: 'product-component' */ '../components/ProductDetailComponent.vue')
const ArtistDetailComponent = () => import(/* webpackChunkName: 'artist-component' */ '../components/artist/ArtistDetailComponent.vue')
const SearchComponent = () => import(/* webpackChunkName: 'search-component' */ '../components/search/SearchComponent.vue')

Vue.use(VueRouter)

const routes = [{
  path: '/',
  component: LandingPageComponent
}, {
  path: '/search',
  component: SearchComponent,
  props: (route) => ({ encodedQuery: route.query.q })
}, {
  path: '/artist/:artistame/:artistid',
  component: ArtistDetailComponent,
  props: (route) => ({ encodedQuery: route.query.q })
}, {
  path: '/product/:ean',
  component: ProductDetailComponent,
  props: true
}, {
  path: '/imprint',
  component: ImprintComponent
}, {
  path: '/data-privacy',
  component: DataPrivacyComponent
}, {
  path: '*',
  component: ErrorComponent
}]
export default new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        offset: { x: 0, y: 90 }
      }
    } else if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})
