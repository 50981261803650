/**
 * FieldTerm for Filtering
 */
export default class FieldTerm {
  /**
     * constructs FieldTerm
     * @param {String} term - internal name
     * @param {String} label - text to display
     */
  constructor (term, label) {
    /**
         * The fieldTerm
         * @type {String}
         */
    this.term = term
    /**
         * The text to display
         * @type {String}
         */
    this.label = label
  }
}
