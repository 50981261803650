/**
 * a FilterTerm
 */
export default class FilterTerm {
  /**
     *
     * @param {String} label
     * @param {String} term
     * @param {number} count
     * @param {boolean} disabled
     * @param {boolean} active
     */
  constructor (label, term, count, disabled, active = false) {
    /**
         * The filter term
         * @type {String}
         */
    this.term = term
    /**
         * Is filter term active?
         * @type {Boolean}
         */
    this.active = active

    /**
         * @type {string}
         */
    this.label = label

    /**
         * @type {number}
         */
    this.count = count

    /**
         * @type {boolean}
         */
    this.disabled = disabled
  }
}
