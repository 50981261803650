import Vue from 'vue'
import store from './store'
import router from './router'
import App from './components/App.vue'
import VuePaginate from 'vue-paginate'
import { BootstrapVue } from 'bootstrap-vue'
import datePicker from 'vue-bootstrap-datetimepicker'
import 'bootstrap-material-design'

import { i18n, loadLanguageAsync, possibleLanguages } from './i18n'
import { logger } from './util/Logger'
import VueLazyload from 'vue-lazyload'
import config from './config'
// Styles
import './assets/scss/vendor.scss'
import './assets/scss/main.scss'

Vue.use(VuePaginate)
Vue.use(BootstrapVue)
Vue.use(datePicker)

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

Vue.use(VueLazyload, {
  preLoad: 2.3,
  observer: !isSafari, // vue lazyload already checks if IntersectionObserver exists in window
  observerOptions: {
    rootMargin: '0px',
    threshold: 0
  },
  silent: !config.debug
})

window.$ = jQuery

Vue.config.productionTip = process.env.NODE_ENV !== 'production'

router.beforeEach((to, from, next) => {
  const lang = to.query.lang
  if (lang !== undefined && lang !== from.query.lang) {
    if (possibleLanguages.includes(lang)) {
      logger.debug('switching to language: ' + lang)
      loadLanguageAsync(lang)
        .then(() => next())
        .catch(() => logger.error('Couldn\'t switch language'))
    } else {
      logger.error('Trying to switch to unrecognized language: ' + lang)
      next()
    }
  } else {
    next()
  }
})

window.vm = new Vue({
  el: '#app',
  i18n,
  store,
  router,
  render: (h) => h(App)
})
