/**
 * Data transfer object for FieldTerms
 */
export default class FieldTermsDTO {
  /**
     * constructor for FieldTermsDTO
     * @param {FieldTermType} type
     * @param {FieldTerm[]} fieldTerms
     */
  constructor (type, fieldTerms) {
    this.type = type

    this.terms = fieldTerms
  }
}
